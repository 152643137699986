define("@woody-lite/addon-conversations/repositories/threads", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/repositories/repository", "@woody-mrs-potato/addon-ember-object-data-model/models/base-array", "@woody-lite/core-session/decorators/session", "ember-utils/utils/strings/unserialize-url", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _repository, _baseArray, _session, _unserializeUrl, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DEFAULT_MANAGER = {
    id: 'ZPHI001'
  };
  let ThreadsRepository = _exports.default = (_dec = (0, _session.default)('client'), _class = class ThreadsRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _initializerDefineProperty(this, "currentSession", _descriptor3, this);
    }
    async getStatus() {
      let data = {};
      const customerId = this.currentSession.id;
      try {
        const params = {
          $fields: 'customerpendingmessagethreads',
          $filter: `(customer.id==${customerId})`
        };
        data = await this.enax.GET('threads', {
          params
        });
      } catch {
        //
      }
      return this.createEntry('conversation', customerId, data);
    }
    _buildThreadsPayload(nextPage) {
      let paginationKey = 1;
      if (nextPage) {
        const nextPageUrl = (0, _unserializeUrl.default)(nextPage);
        paginationKey = encodeURIComponent(nextPageUrl.paginationKey);
      }
      return {
        $filter: `(customer.id==${this.currentSession.id})`,
        $expands: 'messages',
        paginationKey,
        pageSize: 20
      };
    }
    async _getThreads(next) {
      let content = [];
      let nextPage;
      try {
        var _data$pagination;
        const params = this._buildThreadsPayload(next);
        const data = await this.enax.GET('threads', {
          params
        });
        const normalizer = (0, _application.getOwner)(this).lookup('normalizer:thread-message');
        content = ((data === null || data === void 0 ? void 0 : data.messageThreads) || []).map(thread => {
          const contentMessages = thread.messages.map(message => {
            const normalizeMessage = normalizer.normalize(message);
            return this.createEntry('thread-message', normalizeMessage.properties.id, normalizeMessage.properties);
          });
          thread.messages = _baseArray.default.create({
            content: contentMessages
          });
          return this.createEntry('thread', thread.id, thread);
        });
        nextPage = data === null || data === void 0 || (_data$pagination = data.pagination) === null || _data$pagination === void 0 ? void 0 : _data$pagination.nextPage;
      } catch {
        //
      }
      return _baseArray.default.create({
        content,
        nextPage
      });
    }
    _getManager(manager) {
      if (manager.userCode) {
        return {
          id: manager.userCode,
          name: manager.name,
          lastName: manager.lastName,
          mothersLastName: manager.mothersLastName
        };
      }
      return DEFAULT_MANAGER;
    }
    async createThread(subject, message, attachments) {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const payload = {
        subject,
        customer: {
          id: this.currentSession.id,
          name: customer.name,
          lastName: customer.lastName,
          mothersLastName: customer.mothersLastName
        },
        isDraft: false,
        manager: this._getManager((0, _object.get)(customer, 'manager')),
        messages: [{
          content: message,
          sender: {
            type: {
              id: 'C',
              name: 'CLIENTE'
            }
          },
          attachments
        }]
      };
      return this.enax.POST('threads', payload);
    }
    deleteThread(threadId) {
      return this.enax.PUT('threads', {
        isArchived: true
      }, {
        threadId
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});