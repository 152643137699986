define("@woody-lite/engine-sign-module/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('product', {
      path: '/product/:task_id'
    });
    this.route('account');
    this.route('transfer-account');
    this.route('card');
    this.route('conditions');
    this.route('confirmation');
    this.route('mortgage');
    this.route('mortgage-change-account');
    this.route('mortgage-documentation');
    this.route('documentation-confirmation');
    this.route('refinancing-documentation');
    this.route('mortgage-refinancing-info');
  });
});